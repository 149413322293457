<template>
  <div class="list" style="justify-content: center; align-items: center;">
    <b-alert v-model="error" variant="danger" dismissible>
      {{ errorMsg }}
    </b-alert>
    <div v-if="!submitted">
      <h1>Enter MAC-Address</h1>
      <input type="text" class="form-control" id="mac"
             v-model="macAddress"
      />
      <button @click="saveTransaction" class="btn btn-success">buy PVR-Code</button>
    </div>
    <div v-else>
      <h1 style="margin-bottom: 10px;">PVR Code</h1>
      <div
          style="background-color: white; border-radius: 3px; padding: 5px; justify-content: center; text-align: center; align-items: center">
        <h2 style="margin-bottom: 10px;">{{ this.transaction.pvrNumber }}</h2>
      </div>
      <button @click="newPvr" class="btn btn-success mt-5">Get A New PVR-Code</button>
    </div>
  </div>
</template>

<script>
import TransactionDataService from "../services/TransactionDataService";

export default {
  name: "Transactions",
  data() {
    return {
      macAddress: '',
      transaction: {
        id: null,
        userId: null,
        boxTypeId: this.id,
        macAddress: '',
        pvrNumber: '',
      },
      submitted: false,
      error: false,
      errorMsg: 'The entered mac address is not correct, please check the entry again'
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  methods: {
    saveTransaction() {
      let testString = this.macAddress.replace(/[^:]/g, "").length;
      console.log(testString);
      if(this.macAddress.length < 17 || this.macAddress.length > 17 || testString.length < 5){
        this.error = true;
      } else{
        this.error = false;
        let data = {
          userId: 14,
          boxTypeId: this.id,
          macAddress: this.macAddress,
        };
        console.log(this.macAddress);
        console.log(data);
        TransactionDataService.create(data)
            .then(response => {
              this.transaction.id = response.data.id;
              this.transaction.pvrNumber = response.data.PvrNumber;
              console.log(response.data);
              this.submitted = true;
            })
            .catch(e => {
              console.log(e);
            });
      }
    },

    newPvr() {
      this.submitted = false;
      this.transaction.macAddress = '';
      this.transaction.pvrNumber = '';
    },

  },
  mounted() {
    console.log('mounted!');
    //this.retrieveSetTopBoxTypes();
  }
};
</script>

<style>
.list {
  text-align: left;
  max-width: 750px;
  margin: auto;
}
</style>
